import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { React, useState, useRef, useEffect } from "react"
import Arrow from "../../images/svgs/arrow.svg"
import ArrowDown from "../../images/svgs/arrow-down-ac.svg"
import Close from "../../images/svgs/close.svg"
import Drawer from "./drawer"
import Icon from "../icon"
import minus from "../../images/svgs/minus.svg"
import plus from "../../images/svgs/plus.svg"
import tw from "twin.macro"
import uniqueId from "lodash/uniqueId"

const Menu = ({ menuItems, onNavItemClick, collapsed }) => {

  const plusIcon = plus
  const minusIcon = minus

  const [openDrawer, setOpenDrawer] = useState("")
  //const toggleNavigationDrawer = id => setOpenDrawer(id)

  const [accordionItems, setAccordionItems] = useState(
    menuItems.map(({ id, label, childItems, }) => ({
      id: id || uniqueId("accordion-"),
      label: label,
      childItems: childItems?.nodes || [],
      isOpen: false,
    }))
  )


  const toggleNavigationDrawer = index => {

    setOpenDrawer(index)

    setAccordionItems(
      accordionItems.map((accordionItem, i) => {
        if (index === i) {
          accordionItem.isOpen = !accordionItem.isOpen
        }
        return accordionItem
      })
    )
  }

  const navNode = useRef()

  {/*useEffect(() => {
    openDrawer
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)

  }, [openDrawer])*/}

  const handleItemClick = () => {
    onNavItemClick()
  }

  const collapsedStyles = tw`top-32 lg:top-52 xl:top-22 lg:h-[calc(100vh - 8rem)] xl:h-[calc(100vh - 5.5rem)]`
  const normalStyles = tw`lg:top-52 xl:top-48 lg:h-[calc(100vh - 13rem)] xl:h-[calc(100vh - 12rem)]`

  return (
    <nav tw="w-full md:w-[600px]" role="navigation">

      <ul tw="gap-y-1 text-2xl font-bold md:text-3xl ">

        {accordionItems.map(({ label, id, childItems, isOpen }, index) => (

          <li tw="py-1 [border-top: 2px solid black]" className="global-nav-item" key={`nav-${id}`}>

            <button
              onClick={() => {
                toggleNavigationDrawer(index)
              }}
              id={`${id}-header`}
              aria-controls={`${id}-panel`}
              aria-expanded={isOpen}
              tw="flex w-full flex-row cursor-pointer items-center justify-between"
            >
              <span
                tw="block py-3 navLink-animated underline-anthracite lg:underline-lillac"
                css={[openDrawer === id ? tw`navLink-animated-active` : tw``]}
              >
                {label}
              </span>
              <div tw="flex h-10 w-10 items-center justify-center group-hover:bg-lilac-500 group-focus:bg-lilac-500  md:h-9  md:w-9">
                <Icon
                  title={isOpen ? "Details Verbergen" : "Details Anzeigen"}
                  svg={isOpen ? ArrowDown : Arrow}
                  tw="h-6 w-6 text-black group-hover:text-white group-focus:text-white md:h-5 md:w-5"
                />
                <span tw="sr-only">
                  {isOpen ? "Details Verbergen" : "Details Anzeigen"}
                </span>
              </div>
            </button>

            {childItems && childItems.map((item, index) => (
              <div

                aria-labelledby={`${id}-header`}
                hidden={isOpen ? "" : true}
                tw="text-xl font-normal py-1 pl-5"
                key={`panel-${index}`}
              >
                <a
                  href={item.uri}
                  onClick={() => {
                    toggleNavigationDrawer("")
                    handleItemClick()
                    setOpenDrawer("")
                  }}
                >{item.label}</a>
              </div>
            ))}


            {/**<div
              tw="absolute left-0 top-0 z-50 flex h-screen w-screen bg-amber-400 pt-4 md:pt-0"
              css={[
                openDrawer === id ? tw`flex` : tw`hidden`,
                collapsed && collapsedStyles,
                !collapsed && normalStyles,
              ]}
            >
              <div tw="mx-auto flex flex-grow items-start justify-between px-4 pb-2 md:px-10 md:py-7 lg:max-w-screen-2xl lg:py-14 lg:pl-20 lg:pr-20 xl:pl-80">
                <Drawer
                  id={id}
                  label={label + '___'}
                  items={childItems}
                  toggleNavigationDrawer={toggleNavigationDrawer}
                  onItemClick={handleItemClick}
                />
                <button
                  tw="mr-2 hidden h-8 w-8 lg:mt-4 lg:block xl:mt-6"
                  onClick={() => {
                    setOpenDrawer("")
                  }}
                >
                  <Icon
                    svg={Close}
                    title="Menü schließen"
                    tw="h-6 w-6 md:h-8 md:w-8"
                  />
                </button>
              </div>
            </div>**/}

          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu
