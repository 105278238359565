import React from "react"
// import PropTypes from 'prop-types'
import linkedinIcon from "../../images/svgs/linkedin-icon.svg"
import "twin.macro"
import Icon from "../icon"

const LinkedIn = props => {
  return (
    <a
      href="https://www.linkedin.com/company/bundesstiftung-gleichstellung"
      target="_blank"
      rel="noreferrer noopener"
      tw="flex h-11 w-[2.2rem] items-center rounded-full p-[0.5rem 0] text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
      aria-label="LinkedIn"
    >
      <Icon svg={linkedinIcon} tw="h-6 w-6 fill-current" />
    </a>
  )
}

LinkedIn.propTypes = {}

export default LinkedIn
