exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-publikationen-js": () => import("./../../../src/pages/publikationen.js" /* webpackChunkName: "component---src-pages-publikationen-js" */),
  "component---src-templates-calendar-js": () => import("./../../../src/templates/calendar.js" /* webpackChunkName: "component---src-templates-calendar-js" */),
  "component---src-templates-confirmation-js": () => import("./../../../src/templates/confirmation.js" /* webpackChunkName: "component---src-templates-confirmation-js" */),
  "component---src-templates-equalityday-js": () => import("./../../../src/templates/equalityday.js" /* webpackChunkName: "component---src-templates-equalityday-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-latest-detail-js": () => import("./../../../src/templates/latest-detail.js" /* webpackChunkName: "component---src-templates-latest-detail-js" */),
  "component---src-templates-latest-js": () => import("./../../../src/templates/latest.js" /* webpackChunkName: "component---src-templates-latest-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

