import React from "react"
import { Link, graphql } from "gatsby"
import "twin.macro"
import PropTypes from "prop-types"

const Breadcrumbs = ({ items }) => {

  return (
    <nav aria-label="Breadcrumb" tw="flex text-sm">
      <ol tw="inline-flex flex-wrap items-start">
        {items.map((item, index) => (
          <li key={`breadcrumb-${index}`}>
            {/* if page is not published if there is `?page_id=` in the url */}
            {item?.url && item.url !== "" && !item.url.includes("page_id") && ((index + 1) < items.length) ? (
              <Link to={item.url} tw="text-link-negative font-bold">{item.text}</Link>
            ) : (
              <span>{item.text}</span>
            )}
            {index < items.length - 1 && <span tw="mx-1">{"/"}</span>}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Breadcrumbs

export const pageQuery = graphql`
  fragment BreadcrumbsFragment on WpPage {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
  fragment JobBreadcrumbsFragment on WpJob {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
  fragment PostBreadcrumbsFragment on WpPost {
    seo {
      breadcrumbs {
        text
        url
      }
    }
  }
`
