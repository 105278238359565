import { React } from "react"
import LogoWording from "../../../content/assets/logo-wording.svg"
import LogoSignet from "../../../content/assets/logo.svg"
import { Link } from "gatsby"
import Icon from "../icon"
import tw from "twin.macro"

const Logo = ({ collapsed }) => {
  const collapsedStylesLogo = tw`h-10 lg:h-11 xl:h-15`
  const normalStylesLogo = tw`h-10 md:h-18 lg:h-24 xl:h-30`
  const collapsedStylesSignet = tw`w-12 lg:w-4/12 pr-2 block lg:transition-all duration-500 ease-in-out`
  const normalStylesSignet = tw`w-12 lg:w-5/12 pr-2 block lg:transition-all lg:duration-500 ease-in-out`
  const collapsedStylesWording = tw`opacity-0`
  const normalStylesWording = tw`opacity-0 sm:opacity-100 delay-75`

  return (
    <Link
      to="/"
      tw="flex w-32 flex-wrap items-center duration-300 ease-in-out lg:mb-2 lg:w-40 lg:transition-all xl:mb-0 xl:w-48"
      aria-label="Home"
      css={[collapsed && collapsedStylesLogo, !collapsed && normalStylesLogo]}
    >
      <Icon
        svg={LogoSignet}
        title="Bundesstiftung Gleichstellung"
        css={[
          collapsed && collapsedStylesSignet,
          !collapsed && normalStylesSignet,
        ]}
        asImage
      />
      <Icon
        svg={LogoWording}
        title="Bundesstiftung Gleichstellung"
        tw="block w-full transition-all duration-200 ease-in-out"
        css={[
          collapsed && collapsedStylesWording,
          !collapsed && normalStylesWording,
        ]}
        asImage
      />
    </Link>
  )
}

export default Logo
