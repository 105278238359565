import { React } from "react"
import { Link } from "gatsby"
import "twin.macro"
import Icon from "../icon"
import Arrow from "../../images/svgs/arrow.svg"
import "../../css/navigation.css"

const Drawer = ({ items, toggleNavigationDrawer, label, onItemClick }) => {
  const handleClick = () => {
    onItemClick()
  }
  return (
    <ul tw="z-40 grid gap-y-2 lg:block lg:h-full lg:gap-12 lg:pr-10 lg:columns-2 lg:column-fill-auto">

      <li tw="lg:hidden">
        <button
          tw="flex items-center"
          onClick={() => {
            toggleNavigationDrawer("")
          }}
        >
          <Icon
            svg={Arrow}
            title="Zurück zu"
            tw="mt-1 mr-4 h-8 w-5 rotate-180 md:h-9 md:w-6"
          />
          <span tw="inline-block py-3 font-bold text-2xl navLink-animated navLink-animated-active underline-anthracite md:text-3xl lg:text-5xl">
            {label}
          </span>
        </button>
      </li>

      {items.nodes.map(({ label, uri, id }) => (
        <li key={`drawer-${id}`}>
          <Link
            to={uri}
            partiallyActive={true}
            tw="ml-9 inline-block py-3 font-bold text-2xl navLink-animated underline-anthracite lg:ml-0 lg:mb-2 lg:text-4xl xl:text-5xl"
            activeClassName="active"
            onClick={() => {
              toggleNavigationDrawer("")
              handleClick()
            }}
          >
            {label}
          </Link>
        </li>
      ))}
    </ul>
  )
}

Drawer.propTypes = {}

export default Drawer
