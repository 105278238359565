// import Twitter from "../social/twitter"
//import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Link } from "gatsby"
import { React, useEffect, useRef, useState } from "react"
import { throttle } from "lodash"
import { Turn as Hamburger } from "hamburger-react"
import Bar from "./bar"
import Container from "../../components/container"
import Instagram from "../social/instagram"
import LinkedIn from "../social/linkedin"
import Logo from "./logo"
import Menu from "./menu"
import tw from "twin.macro"

const Header = ({ mainMenuItems, footernMenuItems, breadcrumbs }) => {
  const offset = 80
  // const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || []
  const [showNavigation, toggleNavigation] = useState(false)

  const handleSelectedNavItem = () => {
    toggleNavigation(false)
  }

  const navNode = useRef()

  useEffect(() => {
    {/*showNavigation
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)*/}

  }, [showNavigation])

  const [collapsed, setCollapsed] = useState(false)

  const collapsedStyles = tw`top-0 h-[100vh]`
  const normalStyles = tw`top-0  h-[100vh]`
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    const updateScrollState = () => {
      if (isBrowser && window.scrollY > offset && !collapsed) {
        setCollapsed(true)
      }
      if (isBrowser && window.scrollY < offset && collapsed) {
        setCollapsed(false)
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", throttle(updateScrollState, 60))
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", updateScrollState)
      }
    }
  }, [collapsed, isBrowser, offset])

  return (
    <Bar collapsed={collapsed}>
      <Container>
        <div tw="flex items-end justify-between sm:flex-row lg:flex-row xl:flex-row">
          <Logo collapsed={collapsed} />
          <div
            tw="absolute left-0 top-0 [height: 100vh] z-20 h-screen w-full flex-col items-start bg-lilac-500 px-4 pb-2 pt-4 sm:px-10 md:py-5 overflow-auto"
            css={[
              showNavigation ? tw`flex` : tw`hidden`,
              collapsed && collapsedStyles,
              !collapsed && normalStyles,
            ]}
          >
            <div
              ref={navNode}
              tw="flex flex-col items-center w-full justify-end text-sm md:flex pt-18">
              <Menu
                menuItems={mainMenuItems}
                onNavItemClick={handleSelectedNavItem}
                collapsed={collapsed}
              />
              <ul tw="font-bold justify-end w-full md:w-[600px] text-xl md:text-2xl pt-6">
                {footernMenuItems.map(({ label, url, id }) => (
                  <li tw="" key={`nav-${id}`}>
                    <Link
                      to={url || `/`}
                      tw="inline-block py-2"
                      onClick={() => { handleSelectedNavItem("") }}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
              <div tw="gap-2 w-full md:w-[600px] lg:mb-2.5 flex pt-6">
                <div tw="font-bold flex flex-row items-center">
                  <LinkedIn />
                  <a
                    href="https://www.linkedin.com/company/bundesstiftung-gleichstellung"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="LinkedIn"
                  >LinkedIn</a>
                </div>
                <div tw="font-bold flex flex-row items-center ml-3">
                  <Instagram />
                  <a
                    href="https://www.instagram.com/bs.gleichstellung/"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Instagram"
                  >
                  Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div tw="-mr-2 flex items-center">
            <LinkedIn />
            <Instagram />
            <div tw="z-40 flex flex-row items-center bg-lilac-500 ml-6 pl-4 pr-1">
              <span tw="font-bold text-xl pr-3">Menü</span>
              <Hamburger
                toggled={showNavigation}
                label="Navigation anzeigen"
                toggle={state => {
                  toggleNavigation(state)
                }}
                size={32}
              />
            </div>
          </div>

        </div>
      </Container>
    </Bar>
  )
}

export default Header
